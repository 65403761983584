<template lang="pug">
    .layout-general
        form.form-general.card-bg-theme.p-6.rounded.shadow(@submit="submit($event)")
            .form-general__header
                h2 {{ page_title }}
                router-link(to="/sub-features")
                    b-button(text="Kembali" text_icon_left="chevron-left" type="button")
            .form-general__body
                .grid.grid-cols-11.gap-8.mb-16
                    .col-span-4.pr-12
                        h2.text-xl.font-bold.mb-2 Informasi Sub Fitur
                        p.text-gray-400 Pastikan sub fitur diisi sesuai dengan kebutuhan peran.
                    .col-span-7
                        .field
                            label.field-label Nama Sub Fitur
                            input.field-style(placeholder="Nama" type="text" v-model="model.name")
                            span.field-message.text-red-500(v-if="$v.model.name.$error") Nama Fitur wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_name !== ''") {{ error_name }}

                        .field
                            label.field-label Fitur
                             //- field loading
                            .field-style.field-style--loading(v-if="status_list.status === 'loading'")
                                b-loading(kind="circular")

                            .relative(v-else)
                                select.field-style(v-model="model.feature_id")
                                    option(value="") - Pilih Fitur -
                                    //- template(v-if="list.items && list.items.length > 0")
                                    template(v-if="list && list.length > 0")
                                        option(v-for="(feature,index) in list" :key="feature.id" :value="feature.id") {{ feature.name }}
                                .field-icon
                                    b-button(icon_only="chevron-down")
                            span.field-message.text-red-500(v-if="$v.model.feature_id.$error") Fitur wajib diisi.
                            span.field-message.text-red-500(v-if="error_feature_id !== ''") {{ error_feature_id }}

                        .field
                            label.field-label Label
                            input.field-style(placeholder="Label" type="text" v-model="model.label")
                            span.field-message.text-red-500(v-if="$v.model.label.$error") Label wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_label !== ''") {{ error_label }}

                        .field
                            label.field-label Method
                            //- input.field-style(placeholder="Misal POST" type="text" v-model="model.method")
                            select.field-style(v-model="model.method")
                                option(value="") - Pilih Method -
                                option(v-for="(method,index) in request_methods" :key="index" :value="method") {{ method }}
                            .field-icon
                                b-button(icon_only="chevron-down")
                            span.field-message.text-red-500(v-if="$v.model.method.$error") Method wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_method !== ''") {{ error_method }}

                        .field
                            label.field-label Path
                            input.field-style(placeholder="Misal /uri/path" type="text" v-model="model.path")
                            span.field-message.text-red-500(v-if="$v.model.path.$error") Path wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_path !== ''") {{ error_label }}

                        .field
                            label.field-label Deskripsi
                            textarea.field-style(placeholder="Tulis Deskripsi disini..." type="text" v-model="model.description" rows="5")
                            //- span.field-message.text-red-500(v-if="$v.model.description.$error") Deskripsi wajib diisi &amp; maksimal memiliki 255 karakter.
                            span.field-message.text-red-500(v-if="error_description !== ''") {{ error_description }}

            .form-general__footer
                b-button(text="Reset" type="button" @click.native="reset()")
                b-button(text="Simpan" kind="primary" type="submit")

</template>

<script>
import { mapState } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
    name: 'GeneralSubFeaturesForm',
    components: {
        // 
    },
    data() {
        return {
            route_name: '',

            request_methods: [
                'GET',
                'HEAD',
                'POST',
                'PUT',
                'DELETE',
                'CONNECT',
                'OPTIONS',
                'TRACE',
                'PATCH',
            ],

            model: {
                name: '',
                feature_id: '',
                label: '',
                method: '',
                path: '',
                description: '',
            },

            error_name: '',
            error_feature_id: '',
            error_label: '',
            error_method: '',
            error_path: '',
            error_description: '',
        };
    },
    validations: {
        model: {
            name: {
                required,
                maxLength: maxLength(255),
            },
            feature_id: {
                required,
            },
            label: {
                required,
                maxLength: maxLength(255)
            },
            method: {
                required,
                maxLength: maxLength(255)
            },
            path: {
                required,
                maxLength: maxLength(255)
            },
            description: {
                // required,
            },
        },
    },
    computed: {
        ...mapState('subFeatures', ['detail']),
        ...mapState('features', [
            'status_list',
            'list',
        ]),
        id() {
            return this.$route.params.id;
        },
        page_title() {
            let title = 'Ubah Sub Fitur';
            if (this.route_name === 'GeneralSubFeaturesCreate') {
                title = 'Tambah Sub Fitur';
            }
            return title;
        },
    },
    watch: {
        id() {},
        page_title() {},
        // form data
        detail(newData) {
            // console.log(newData)
            this.model.name = (newData.name ? newData.name : '');
            this.model.feature_id = (newData.feature_id ? newData.feature_id : '');
            this.model.label = (newData.label ? newData.label : '');
            this.model.method = (newData.method ? newData.method : '');
            this.model.path = (newData.path ? newData.path : '');
            this.model.description = (newData.description ? newData.description : '');
        },
        'model.label': function(newData) {
            if (newData !== '' && newData !== null) {
                this.model.label = newData.replace(/\s+/g, '-');
            }
        },
        'model.path': function(newData) {
            if (newData !== '' && newData !== null) {
                this.model.path = newData.replace(/\s+/g, '-').toLowerCase();
            }
        },
    },
    methods: {
        setEmptyModel(all=true) {
            for (const key in Object.keys(this.model)) {
                if (all === true) {
                    this.model[Object.keys(this.model)[key]] = '';
                }
            }
        },
        setErrors(empty=true, errors=null) {
            if (empty === true) {
                this.error_name = '';
                this.error_feature_id = '';
                this.error_label = '';
                this.error_method = '';
                this.error_path = '';
                this.error_description = '';
            } else if (empty !== true && errors !== null) {
                this.error_name = errors.name ? errors.name : '';
                this.error_feature_id = errors.feature_id ? errors.feature_id : '';
                this.error_label = errors.label ? errors.label : '';
                this.error_method = errors.method ? errors.method : '';
                this.error_path = errors.path ? errors.path : '';
                this.error_description = errors.description ? errors.description : '';
            }
        },
        async reset(direct=false) {
            if (direct === true) {
                // this.setEmptyModel(false);
                this.setEmptyModel(true);
                // reset vuelidate
                this.$nextTick(() => {
                    this.$v.$reset();
                });
                this.setErrors(true);
                // window.scrollTo(0,0);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda benar-benar ingin mengosongkan semua data yang sudah diisi?',
                    // text: id,
                    confirmButtonText: 'ya',
                    confirmButtonColor: '#DD6B55',
                    cancelButtonText: 'Tidak',
                    showCancelButton: true,
                    allowOutsideClick: false,
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                        // if (this.route_name === 'GeneralProfilePage') {
                        //     this.setEmptyModel(false);
                        //     // reset vuelidate
                        //     this.$nextTick(() => { this.$v.$reset(); });
                        //     this.setErrors(true);
                        //     window.scrollTo(0,0);
                        // } else {
                            this.getData();
                        // }
                        }
                    });
            }
        },
        getData(update=false) {
            this.reset(true);

            if (this.route_name === 'GeneralSubFeaturesEdit') {
                Event.$emit('showLoading', true);
            }

            this.$store.dispatch('features/getList', [100, 1]);

            if (this.route_name === 'GeneralSubFeaturesEdit') {
                // Event.$emit('showLoading', true);

                // this.$store.commit('subFeatures/setDetail', this.decodeDetailData());
                // // temp
                // Event.$emit('showLoading', false);

                this.$store.dispatch('subFeatures/getDetail', this.id)
                    .then((resp) => {
                        if (resp === 'success') {
                            Event.$emit('showLoading', false);
                        } else {
                            Event.$emit('error');
                        }
                    });
            } else {
                // if (this.route_name === 'GeneralSubFeaturesCreate')
                this.$store.commit('subFeatures/setDetail', {});
            }
        },
        submit(e, save_continue=false) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (const key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes('$')) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                // Event.$emit('error', 'Please fill the form correctly.');
                // Event.$emit('showLoading', false);
                window.scrollTo(0, 0);
                return;
            }

            Event.$emit('showLoading', true);
            let model_data = this.model;
            // model_data.gatra = JSON.stringify(model_data.gatra);
            // console.log(model_data);
            model_data = Object.keys(model_data).map((key) => model_data[key]);

            if (this.route_name === 'GeneralSubFeaturesCreate') {
                this.$store.dispatch('subFeatures/create', model_data)
                    .then((resp) => {
                        if (resp.result === 'success') {
                            if (save_continue === false) {
                                this.$router.push('/sub-features');
                            } else {
                                this.$router.push('/sub-features/'+ resp.data.data.id +'/edit');
                            }

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Fitur berhasil dibuat',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data && resp.data.message && resp.data.message.errors) {
                            // this.setErrors(false, resp.data.message.errors);
                            // Event.$emit('error', null, resp.data.message.errors);
                                Event.$emit('showLoading', false);
                                window.scrollTo(0, 0);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });

            // GeneralSubFeaturesEdit
            } else {
                model_data = [this.id].concat(model_data);

                this.$store.dispatch('subFeatures/update', model_data)
                    .then((resp) => {
                        if (resp.result === 'success') {
                            if (save_continue === false) {
                                this.$router.push('/sub-features');
                            }

                            this.$swal.fire({
                                icon: 'success',
                                title: 'Fitur berhasil diperbarui',
                                timer: 3000,
                            });
                        } else {
                            if (resp.data.message && resp.data.message.errors) {
                                this.setErrors(false, resp.data.message.errors);
                                // Event.$emit('error', null, resp.data.message.errors);
                                Event.$emit('showLoading', false);
                                window.scrollTo(0, 0);
                            } else {
                                Event.$emit('error');
                            }
                        }
                    });
            }
        },
    },
    created() {
        //
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.route_name = to.name;
            if (to.name === 'GeneralSubFeaturesCreate' || to.name === 'GeneralSubFeaturesEdit') {
                vm.getData();
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        this.route_name = '';
        this.$store.commit('subFeatures/setDetail', {});
        next();
    },
    mounted() {
        // this.getData();
    },
};
</script>
